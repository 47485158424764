import Book from 'src/models/fb/Book'

export default function () {
  return {
    uploading: false,
    url: false,
    assets: {},
    allAssets: [],
    Books: [],
    BooksPublished: [],
    BooksOwned: [],
    // tagsInUse: ['Scary', 'Funny', 'Favorite', 'Mom', 'Dad'],
    currentBook: new Book(),
    mirrorPages: [],
    Meetup: {},
    filtered: '*',
    included: process.env.DEV ? ['!'] : ['*', '!', '@'], // I own !, published @, borrowing *
    // included: ['*', '!', '@'],
    tagsInUse: {
      Books: [],
      BooksPublished: [],
      BooksOwned: [],
      all: [],
    },
    autoNext: true,
    allowed_vx: ['tags', 'library'],
    lockedForKid: false, // process.env.DEV,

/*    currentBook2: {
      'title': 'Count to Ten',
      'author': 'Sesame Street',
      'cover': {
        'name': 'book_cover.png',
        'full': 'gUi53M613cTPYYzo7jz5Pfj9DPC3/assets/book_cover.png',
        'URL': 'https://firebasestorage.googleapis.com/v0/b/myfamiliarvoices.appspot.com/o/gUi53M613cTPYYzo7jz5Pfj9DPC3%2Fassets%2Fbook_cover.png?alt=media&token=a3dcd83f-af7d-4dd4-85fe-43febf787b00',
        'ext': 'png',
        'page': -1,
        'parts': [
          'book_cover',
          'png'
        ]
      },
      'pages': [
        {
          'images': [
            {
              'URL': 'https://firebasestorage.googleapis.com/v0/b/myfamiliarvoices.appspot.com/o/gUi53M613cTPYYzo7jz5Pfj9DPC3%2Fassets%2Fp0.png?alt=media&token=315e0c2b-839e-4346-978a-6fbac1465471',
              'full': 'gUi53M613cTPYYzo7jz5Pfj9DPC3/assets/p0.png',
              'ext': 'png'
            },
            {
              'URL': 'https://firebasestorage.googleapis.com/v0/b/myfamiliarvoices.appspot.com/o/gUi53M613cTPYYzo7jz5Pfj9DPC3%2Fassets%2Fp1.png?alt=media&token=c92ad79b-f3e8-4c20-be09-429c99e1de98',
              'full': 'gUi53M613cTPYYzo7jz5Pfj9DPC3/assets/p1.png',
              'ext': 'png'
            },
            {
              'URL': 'https://firebasestorage.googleapis.com/v0/b/myfamiliarvoices.appspot.com/o/gUi53M613cTPYYzo7jz5Pfj9DPC3%2Fassets%2Fp2.png?alt=media&token=4dca507e-e3fc-4980-a67d-0cc5f714a969',
              'full': 'gUi53M613cTPYYzo7jz5Pfj9DPC3/assets/p2.png',
              'ext': 'png'
            },
            {
              'URL': 'https://firebasestorage.googleapis.com/v0/b/myfamiliarvoices.appspot.com/o/gUi53M613cTPYYzo7jz5Pfj9DPC3%2Fassets%2Fp3.png?alt=media&token=7b93b9f4-8606-48bb-abc6-fdf387949840',
              'full': 'gUi53M613cTPYYzo7jz5Pfj9DPC3/assets/p3.png',
              'ext': 'png'
            }
          ],
          'sounds': [
            {
              'URL': 'https://firebasestorage.googleapis.com/v0/b/myfamiliarvoices.appspot.com/o/gUi53M613cTPYYzo7jz5Pfj9DPC3%2Fassets%2Fp0.m4a?alt=media&token=33ff14f0-ff52-40ac-93e7-86e78be1f383',
              'full': 'gUi53M613cTPYYzo7jz5Pfj9DPC3/assets/p0.m4a',
              'ext': 'm4a'
            },
          ]
        },
        {
          'images': [
            {
              'aURL': 'https://firebasestorage.googleapis.com/v0/b/myfamiliarvoices.appspot.com/o/gUi53M613cTPYYzo7jz5Pfj9DPC3%2Fassets%2Fp1.png?alt=media&token=c92ad79b-f3e8-4c20-be09-429c99e1de98',
               'URL': 'https://firebasestorage.googleapis.com/v0/b/myfamiliarvoices.appspot.com/o/gUi53M613cTPYYzo7jz5Pfj9DPC3%2Fassets%2Fp1.png?alt=media&token=a3ff4520-1383-4cb3-b254-d148ce774230',
              'full': 'gUi53M613cTPYYzo7jz5Pfj9DPC3/assets/p1.png',

              'ext': 'png'
            },
            {
              'URL': 'https://firebasestorage.googleapis.com/v0/b/myfamiliarvoices.appspot.com/o/gUi53M613cTPYYzo7jz5Pfj9DPC3%2Fassets%2Fp0.png?alt=media&token=315e0c2b-839e-4346-978a-6fbac1465471',
              'full': 'gUi53M613cTPYYzo7jz5Pfj9DPC3/assets/p0.png',
              'ext': 'png'
            }
          ],
          'sounds': [
            {
              'URL': 'https://firebasestorage.googleapis.com/v0/b/myfamiliarvoices.appspot.com/o/gUi53M613cTPYYzo7jz5Pfj9DPC3%2Fassets%2Fp1.m4a?alt=media&token=85c71543-2253-44dd-a058-125fbe9a700a',
              'full': 'gUi53M613cTPYYzo7jz5Pfj9DPC3/assets/p1.m4a',
              'ext': 'm4a'
            },
          ]
        },
        {
          'images': [
            {
              'URL': 'https://firebasestorage.googleapis.com/v0/b/myfamiliarvoices.appspot.com/o/gUi53M613cTPYYzo7jz5Pfj9DPC3%2Fassets%2Fp2.png?alt=media&token=4dca507e-e3fc-4980-a67d-0cc5f714a969',
              'full': 'gUi53M613cTPYYzo7jz5Pfj9DPC3/assets/p2.png',
              'ext': 'png'
            }
          ],
          'sounds': [
            {
              'URL': 'https://firebasestorage.googleapis.com/v0/b/myfamiliarvoices.appspot.com/o/gUi53M613cTPYYzo7jz5Pfj9DPC3%2Fassets%2Fp2.m4a?alt=media&token=5d4c5734-27a1-4548-ad98-1d27bcf771b5',
              'full': 'gUi53M613cTPYYzo7jz5Pfj9DPC3/assets/p2.m4a',
              'ext': 'm4a'
            }
          ]
        },
        {
          'images': [
            {
              'URL': 'https://firebasestorage.googleapis.com/v0/b/myfamiliarvoices.appspot.com/o/gUi53M613cTPYYzo7jz5Pfj9DPC3%2Fassets%2Fp3.png?alt=media&token=7b93b9f4-8606-48bb-abc6-fdf387949840',
              'full': 'gUi53M613cTPYYzo7jz5Pfj9DPC3/assets/p3.png',
              'ext': 'png'
            }
          ],
          'sounds': [
            {
              'URL': 'https://firebasestorage.googleapis.com/v0/b/myfamiliarvoices.appspot.com/o/gUi53M613cTPYYzo7jz5Pfj9DPC3%2Fassets%2Fp3.m4a?alt=media&token=8daaf597-8997-440c-8d0f-a1b121cd1b07',
              'full': 'gUi53M613cTPYYzo7jz5Pfj9DPC3/assets/p3.m4a',
              'ext': 'm4a'
            }
          ]
        },
        {
          'images': [
            {
              'URL': 'https://firebasestorage.googleapis.com/v0/b/myfamiliarvoices.appspot.com/o/gUi53M613cTPYYzo7jz5Pfj9DPC3%2Fassets%2Fp4.png?alt=media&token=9d982aee-ec90-41e8-85b7-32b125c144f1',
              'full': 'gUi53M613cTPYYzo7jz5Pfj9DPC3/assets/p4.png',
              'ext': 'png'
            }
          ],
          'sounds': [
            {
              'URL': 'https://firebasestorage.googleapis.com/v0/b/myfamiliarvoices.appspot.com/o/gUi53M613cTPYYzo7jz5Pfj9DPC3%2Fassets%2Fp4.m4a?alt=media&token=5a4a2964-b1a3-4b93-9e04-a774b2915189',
              'full': 'gUi53M613cTPYYzo7jz5Pfj9DPC3/assets/p4.m4a',
              'ext': 'm4a'
            }
          ]
        },
        {
          'images': [
            {
              'URL': 'https://firebasestorage.googleapis.com/v0/b/myfamiliarvoices.appspot.com/o/gUi53M613cTPYYzo7jz5Pfj9DPC3%2Fassets%2Fp5.png?alt=media&token=86dc9783-4cc2-4321-8f1b-2ae2a769323c',
              'full': 'gUi53M613cTPYYzo7jz5Pfj9DPC3/assets/p5.png',
              'ext': 'png'
            }
          ],
          'sounds': [
            {
              'URL': 'https://firebasestorage.googleapis.com/v0/b/myfamiliarvoices.appspot.com/o/gUi53M613cTPYYzo7jz5Pfj9DPC3%2Fassets%2Fp5.m4a?alt=media&token=d4192a1c-6333-4fca-95fa-642ca3ef1777',
              'full': 'gUi53M613cTPYYzo7jz5Pfj9DPC3/assets/p5.m4a',
              'ext': 'm4a'
            }
          ]
        },
        {
          'images': [
            {
              'URL': 'https://firebasestorage.googleapis.com/v0/b/myfamiliarvoices.appspot.com/o/gUi53M613cTPYYzo7jz5Pfj9DPC3%2Fassets%2Fp6.png?alt=media&token=0b7ecb15-70c0-4991-b83c-23a83da6b09e',
              'full': 'gUi53M613cTPYYzo7jz5Pfj9DPC3/assets/p6.png',
              'ext': 'png'
            }
          ],
          'sounds': [
            {
              'URL': 'https://firebasestorage.googleapis.com/v0/b/myfamiliarvoices.appspot.com/o/gUi53M613cTPYYzo7jz5Pfj9DPC3%2Fassets%2Fp6.m4a?alt=media&token=c2c84671-6b99-4d5e-9537-946f864c3890',
              'full': 'gUi53M613cTPYYzo7jz5Pfj9DPC3/assets/p6.m4a',
              'ext': 'm4a'
            }
          ]
        },
        {
          'images': [
            {
              'URL': 'https://firebasestorage.googleapis.com/v0/b/myfamiliarvoices.appspot.com/o/gUi53M613cTPYYzo7jz5Pfj9DPC3%2Fassets%2Fp7.png?alt=media&token=7149adc8-1624-4020-b81f-5d83bef999fb',
              'full': 'gUi53M613cTPYYzo7jz5Pfj9DPC3/assets/p7.png',
              'ext': 'png'
            }
          ],
          'sounds': [
            {
              'URL': 'https://firebasestorage.googleapis.com/v0/b/myfamiliarvoices.appspot.com/o/gUi53M613cTPYYzo7jz5Pfj9DPC3%2Fassets%2Fp7.m4a?alt=media&token=236fc106-984c-4185-9324-ea8546fd6d5b',
              'full': 'gUi53M613cTPYYzo7jz5Pfj9DPC3/assets/p7.m4a',
              'ext': 'm4a'
            }
          ]
        },
        {
          'images': [
            {
              'URL': 'https://firebasestorage.googleapis.com/v0/b/myfamiliarvoices.appspot.com/o/gUi53M613cTPYYzo7jz5Pfj9DPC3%2Fassets%2Fp8.png?alt=media&token=fbb42367-cf6a-4ee0-a1d0-2e6cc089d464',
              'full': 'gUi53M613cTPYYzo7jz5Pfj9DPC3/assets/p8.png',
              'ext': 'png'
            }
          ],
          'sounds': [
            {
              'URL': 'https://firebasestorage.googleapis.com/v0/b/myfamiliarvoices.appspot.com/o/gUi53M613cTPYYzo7jz5Pfj9DPC3%2Fassets%2Fp8.m4a?alt=media&token=d83a5f91-e57b-490c-9fbb-9e23847569b4',
              'full': 'gUi53M613cTPYYzo7jz5Pfj9DPC3/assets/p8.m4a',
              'ext': 'm4a'
            }
          ]
        },
        {
          'images': [
            {
              'URL': 'https://firebasestorage.googleapis.com/v0/b/myfamiliarvoices.appspot.com/o/gUi53M613cTPYYzo7jz5Pfj9DPC3%2Fassets%2Fp9.png?alt=media&token=873ad78c-9d56-4f4a-9ea3-b4085369381c',
              'full': 'gUi53M613cTPYYzo7jz5Pfj9DPC3/assets/p9.png',
              'ext': 'png'
            }
          ],
          'sounds': [
            {
              'URL': 'https://firebasestorage.googleapis.com/v0/b/myfamiliarvoices.appspot.com/o/gUi53M613cTPYYzo7jz5Pfj9DPC3%2Fassets%2Fp9.m4a?alt=media&token=279da3fb-1684-46d4-a0c0-7a3cebf8defa',
              'full': 'gUi53M613cTPYYzo7jz5Pfj9DPC3/assets/p9.m4a',
              'ext': 'm4a'
            }
          ]
        },
        {
          'images': [
            {
              'URL': 'https://firebasestorage.googleapis.com/v0/b/myfamiliarvoices.appspot.com/o/gUi53M613cTPYYzo7jz5Pfj9DPC3%2Fassets%2Fp10.png?alt=media&token=270ab0a2-8b89-4a7e-b615-c2bd0978409f',
              'full': 'gUi53M613cTPYYzo7jz5Pfj9DPC3/assets/p10.png',
              'ext': 'png'
            }
          ],
          'sounds': [
            {
              'URL': 'https://firebasestorage.googleapis.com/v0/b/myfamiliarvoices.appspot.com/o/gUi53M613cTPYYzo7jz5Pfj9DPC3%2Fassets%2Fp10.m4a?alt=media&token=cd235b33-ded9-4ee7-9e67-7de43de7fb57',
              'full': 'gUi53M613cTPYYzo7jz5Pfj9DPC3/assets/p10.m4a',
              'ext': 'm4a'
            }
          ]
        },
        {
          'images': [
            {
              'URL': 'https://firebasestorage.googleapis.com/v0/b/myfamiliarvoices.appspot.com/o/gUi53M613cTPYYzo7jz5Pfj9DPC3%2Fassets%2Fp11.png?alt=media&token=ed300eb8-ffde-4895-890b-4bbc9386a423',
              'full': 'gUi53M613cTPYYzo7jz5Pfj9DPC3/assets/p11.png',
              'ext': 'png'
            }
          ],
          'sounds': [
            {
              'URL': 'https://firebasestorage.googleapis.com/v0/b/myfamiliarvoices.appspot.com/o/gUi53M613cTPYYzo7jz5Pfj9DPC3%2Fassets%2Fp11.m4a?alt=media&token=651d1ec8-2cb2-441e-9cd9-8092b1905286',
              'full': 'gUi53M613cTPYYzo7jz5Pfj9DPC3/assets/p11.m4a',
              'ext': 'm4a'
            }
          ]
        }
      ]
    } */
  }
}
