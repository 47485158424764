import Book from 'src/models/fb/Book'
import {firestoreAction} from 'vuexfire'
// import { Platform, LocalStorage, uid } from 'quasar'
import {collectionRef, userRef} from 'src/services/firebase/db.js'

export async function deleteAsset ({rootState, state, commit}, payload) {
  const fullname = `/${rootState.auth.uid}/assets/${payload}`
  const myAsset = this.$fb.storageRef(fullname)
  return myAsset.delete()
}

export async function saveAsset ({rootState, state, commit}, payload) {
  // nav store 5
  // called 4 times?

  const newRecord = payload.newRecord
  // https://firebase.google.com/docs/storage/web/upload-files
  const fullname = `/${rootState.auth.uid}/assets/${payload.name}`
  const myAsset = this.$fb.storageRef(fullname)
  newRecord.full = fullname
  commit('updatePropData', {prop: 'uploading', val: fullname})
  commit('updatePropData', {prop: 'url', val: false})
  // File or Blob named mountains.jpg
  const file = payload.file

// Create the file metadata
  const metadata = payload.meta || {
    contentType: 'image/jpeg',
  }

  console.log('Meta used: ' + metadata)
// Upload file and metadata to the object 'images/mountains.jpg'
  let uploadTask
  if ('binary' in file) {
    uploadTask = myAsset.put(file.binary, metadata)
  } else {
    uploadTask = myAsset.put(file, metadata)
  }
// Listen for state changes, errors, and completion of the upload.
  uploadTask.on('state_changed',
    function (snapshot) {
      // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      console.log('Upload is ' + progress + '% done')
      switch (snapshot.state) {
        case 'paused':
          console.log('Upload is paused')
          break
        case 'running':
          console.log('Upload is running')
          break
      }
    }, function (error) {
      // A full list of error codes is available at
      // https://firebase.google.com/docs/storage/web/handle-errors
      switch (error.code) {
        case 'storage/unauthorized':
          console.log('User does not have permission to access the object')
          break

        case 'storage/canceled':
          console.log('User canceled the upload')
          break

        case 'storage/unknown':
          console.log('Unknown error occurred, inspect error.serverResponse')
          break
      }
    }, function () {
      // Upload completed successfully, now we can get the download URL
      uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
        console.log('newRecord')
        console.dir(newRecord)
        newRecord.URL = downloadURL
        commit('updatePropData', {prop: 'url', val: downloadURL})
        commit('updatePage', newRecord)
        console.log('File available at', downloadURL)
      })
    })
}

export async function getAssets ({rootState, state, commit}) {
  const myAssets = this.$fb.storageRef(`/${rootState.auth.uid}/assets`)
  // TODO: paginated!  https://firebase.google.com/docs/storage/web/list-files
  myAssets.listAll().then(result => {
    // commit('updatePropData', {prop: 'assets', val: {}})

    result.items.forEach(async ref => {
      const parts = ref.name.split('.')
      let ext, page
      if (parts.length > 1) {
        ext = parts[parts.length - 1]
        page = parseInt(parts[0].substr(1))
        if (Object.is(page, Number.NaN)) page = -1
      } else {
        ext = ref.name
        page = -1
      }

      ref.getDownloadURL().then(URL => {
        const adding = {
          name: ref.name,
          full: ref.fullPath,
          // url: ref.getDownloadURL(),
          URL,
          ext,
          page,
          parts,
        }

        commit('addToBook', adding)
      })
    })
  })
}

export async function addRole ({rootState, state, commit}, payload) {
  // fGpkM5lUcOYO9sAm6BTRzcAGEYk1
}

export async function update ({rootState, state, commit, dispatch}, payload) {
  if (!payload.editors.includes(rootState.auth.uid)) {
    const modifiable = Object.assign({}, payload)
    modifiable.editors.push(rootState.auth.uid)
    return dispatch('update', modifiable)
  }

  return userRef('Books', payload.id).set(payload, {merge: true})
    .then(answer => {
      console.log(answer)
    })
    .catch(err => {
      console.log(err)
    })

}

export async function publish ({rootState, state, commit}, payload) {
  let ref
  let docAdded = false
  let data = payload
  data.created = new Date()
  data.owner = rootState.auth.uid
  data.editors = data.editors || []
  if (!data.editors.includes(rootState.auth.uid)) {
    data.editors.push(rootState.auth.uid)
  }
  try {
    ref = await collectionRef('Books').add(data)
    try {
      docAdded = await ref
      collectionRef('Books').doc(docAdded.id).update({id: docAdded.id})
    } catch (err) {
      console.log(err)
    }
    // let ref = await collectionRef('Books').doc(data.owner).add(data)
    // data.ref = ref

    if (data instanceof Book) commit('updatePropData', {prop: 'currentBook', val: data})
    else commit('updatePropData', {prop: 'currentBook', val: new Book(data)})

  } catch (err) {
    console.log(err)
  }
  return docAdded
}

export async function gatherTags ({state, commit}) {
  const tagsInUse = {
    Books: [],
    BooksPublished: [],
    BooksOwned: [],
    all: [],
  }

  const keys = ['Books', 'BooksPublished', 'BooksOwned']

  keys.forEach(lib => {
    state[lib].forEach(book => {
      tagsInUse[lib].push(...book.tags)
      tagsInUse.all.push(...book.tags)
    })
    tagsInUse[lib] = Array.from(new Set(tagsInUse[lib]))

    // add some likely ones
    tagsInUse.all.push(...['❤️', '😂', '🛏️', '🌳', '👻', '💕', '☃️', '🏅', '🐇', '🙈'])
    tagsInUse.all = Array.from(new Set(tagsInUse.all))
  })

  console.log(tagsInUse)
  commit('updatePropData', {
    prop: 'tagsInUse',
    val: tagsInUse,
  })
}

export const getBooksReadable = firestoreAction(
  ({
     rootState,
     bindFirestoreRef,
     unbindFirestoreRef,
   }) => {
    console.log('entry:getBooksReadable: ' + rootState.auth.uid)
    return bindFirestoreRef('Books', collectionRef('Books')
      .where('readers', 'array-contains', rootState.auth.uid))
  },
)
export const getBooksOwned = firestoreAction(
  ({
     rootState,
     bindFirestoreRef,
     unbindFirestoreRef,
   }) => {
    console.log('entry:getBooksOwned: ' + rootState.auth.uid)
    return bindFirestoreRef('BooksOwned', collectionRef('Books')
      .where('owner', '==', rootState.auth.uid))
  },
)
export const getBooksPublished = firestoreAction(
  ({
     rootState,
     bindFirestoreRef,
     unbindFirestoreRef,
   }) => {
    console.log('entry:getBooksPublished: ' + rootState.auth.uid)
    return bindFirestoreRef('BooksPublished', collectionRef('Books')
      .where('published', '==', true))
  },
)
export const getSpecificBook = firestoreAction(
  ({
     rootState,
     bindFirestoreRef,
     unbindFirestoreRef,
   }, payload) => {
    console.log('entry:getSpecificBook: ' + payload)
    return bindFirestoreRef('TheBook', collectionRef('Books')
      .doc(payload))
  },
)

export const getMeetup = firestoreAction(
  ({
     rootState,
     bindFirestoreRef,
     unbindFirestoreRef,
   }, payload) => {
    console.log('entry:getSpecificBook: ' + payload)
    return bindFirestoreRef('Meetup', collectionRef('meetup')
      .doc(payload))
  },
)
export const getUsers = firestoreAction(
  ({
     rootState,
     bindFirestoreRef,
     unbindFirestoreRef,
   }, userList) => {
    console.log('LinkedUsers entry:getUsers: ' + JSON.stringify(userList))
    return bindFirestoreRef('LinkedUsers', collectionRef('users')
      .where('id', 'in', userList))
    // .where('id', 'array-contains-any', JSON.stringify(userList)))
  },
)
