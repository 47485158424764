const routes = [
  {
    path: '/',
    component: () => import('layouts/User.vue'),
    children: [
      {
        path: '/',
        component: () => import('pages/fb/Books'),
      },
      {
        path: 'tos',
        component: () => import('pages/TOS.vue')
        // component: () => import('pages/fbAuth')
      },
      {
        path: 'privacy',
        component: () => import('pages/PrivacyPolicy')
        // component: () => import('pages/fbAuth')
      },
      {
        path: '/about',
        component: () => import('layouts/User.vue'),
        children: [
          {
            path: '',
            component: () => import('pages/About')
          }
        ]
      },
    ]
  },
  {
    path: '/auth',
    component: () => import('layouts/Basic.vue'),
    children: [
      {
        path: 'forgotPassword',
        name: 'ForgotPassword',
        component: () => import('pages/ForgotPassword.vue')
      },
      {
        path: 'login',
        name: 'Login',
        component: () => import('pages/Auth.vue')
      },
      {
        path: 'register',
        name: 'Register',
        component: () => import('pages/Auth.vue')
      }
    ]
  },
  {
    path: '/user',
    component: () => import('layouts/User.vue'),
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: '/Assets',
        component: () => import ('pages/fb/Assets')
      },
      {
        path: '/books',
        component: () => import ('pages/fb/Books')
      },
      {
        path: '/play',
        component: () => import('pages/fb/BookPlay')
      },
      {
        path: '/:title/play/:bookId',
        component: () => import('pages/fb/BookPlay'),
        props: true,
      },
      {
        path: '/:title/card/:id',
        component: () => import('pages/fb/card'),
        props: true,
      },
      {
        path: '/book/:bookId/page/:pageNumber',
        component: () => import('pages/fb/Pages'),
        props: true,
      },
      {
        path: '/bookEditor/new',
        props: true,
        name: 'createBook',
        component: () => import('pages/fb/BookEditor')
      },
      {
        path: '/bookEditor/new/:id',
        name: 'createBook',
        props: true,
        component: () => import('pages/fb/BookEditor')
      },
      {
        path: '/bookEditor',
        name: 'editBook',
        props: true,
        component: () => import('pages/fb/BookEditor')
      },
      {
        path: '/bookold',
        component: () => import ('pages/fb/Book')
      },
      {
        path: '/family',
        component: () => import('pages/fb/Family')
      },

      {
        path: 'profile',
        name: 'UserProfile',
        component: () => import('pages/user/Profile.vue'),
        meta: {
          requiresAuth: true
        }
      }
    ]
  },

]

// Always leave this as last one
if (process.env.MODE !== 'ssr') {
  routes.push({
    path: '*',
    component: () => import('pages/Error404.vue')
  })
}

export default routes
