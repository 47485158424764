export function updatePropData (state, payload) {
  state[payload.prop] = payload.val

}

export function updatePage (state, payload) {
  console.log(payload)
  // state.currentBook.pages[payload.page] = state.currentBook.pages[payload.page] || {
  //   images: [],
  //   sounds: [],
  //   index: payload.page,
  //   page: payload.page,
  // }
  const holder = state.currentBook.pages[payload.page][payload.collection]
    holder[payload.nth].full = payload.full
    holder[payload.nth].URL = payload.URL

  state.currentBook.pages[payload.page] = state.currentBook.pages[payload.page]
  state.mirrorPages = [...state.currentBook.pages]
}

export function addToBook (state, payload) {
  const wtf = state.assets
  wtf[payload.name] = payload
  state.assets = wtf
  state.allAssets.push(payload)
  state.allAssets.sort((a, b) => {
    const diff = a.page - b.page
    if (diff === 0) {
      return a.ext < b.ext ? 1 : -1
    } else {
      return diff
    }
  })
  // this.$set(state.assets, payload.name, payload)
  // state.assets = state.assets
}

