import gtm from 'src/components/fb/gtm'
// "async" is optional;
// more info on params: https://quasar.dev/quasar-cli/boot-files
export default async ({ router } /* { app, router, Vue ... } */) => {
  // something to do
  router.afterEach((to, from) => {
    gtm.logPage(to.path)
    console.log('gtm logPath: ' + to.path)
  })
}
