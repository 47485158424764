/** Class representing a User. */
export default class User {

/*   */

  /**
   * @param  {Object} args - User arguments supplied during
   * user creation
   */
  constructor (args) {
    this.id = ''
    this.backgroundPhoto = ''
    this.email = ''
    this.fullName = ''
    this.mobile = ''
    this.profilePhoto = ''

    Object.keys(args).forEach((v, i) => {
      this[v] = args[v]
    })

    return {
      ...this
    }
  }
}
