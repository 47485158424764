import firebaseService from '../services/firebase'

export default ({ router, store, Vue }) => {
  // const config = process.env.environments.FIREBASE_CONFIG
  const config = {
    apiKey: "AIzaSyAk5TZ2a28LBG0b_Q9XUYleuoQBtAzcp_o",
    authDomain: "myfamiliarvoices.firebaseapp.com",
    databaseURL: "https://myfamiliarvoices.firebaseio.com",
    projectId: "myfamiliarvoices",
    storageBucket: "myfamiliarvoices.appspot.com",
    messagingSenderId: "781379948264",
    appId: "1:781379948264:web:f52ec97ee9c0971717835d",
    measurementId: "G-S5HL0634DN"
  }
  firebaseService.fBInit(config)

  // Tell the application what to do when the
  // authentication state has changed
  firebaseService.auth().onAuthStateChanged((currentUser) => {
    firebaseService.handleOnAuthStateChanged(store, currentUser)
  }, (error) => {
    console.error(error)
  })

  // Setup the router to be intercepted on each route.
  // This allows the application to halt rendering until
  // Firebase is finished with its initialization process,
  // and handle the user accordingly
  firebaseService.routerBeforeEach(router, store)

  Vue.prototype.$fb = firebaseService
  store.$fb = firebaseService
}
