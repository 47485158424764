export default class Book {
  constructor (args) {
    this.fieldsToCopyToJSON = [
      'id',
      'title',
      'author',
      'artist',
      'owner',
      'ownerImage',
      'editors',
      'readers',
      'tags',
      'created',
      'updated',
      'public',
      'published',
      'mapString'
    ]
    // REMEMBER to construct accessor methods for any new fields above - needef for the combined/base concept
    this.guardedFields = ['greeting', 'cover']
    this.subfields = ['URL', 'full', 'ext']
    this.base = {}
    this.autoSave = true
    this.baseIDs = []
    // this.baseIDs = ['rYTJf5jYW9u30qiHIvES']
    this.mergedPages = false
    // this.baseStrategy = 'bf' // bl

    // 'greeting', 'cover', have subset of field moved in asJSON
    this.dirty = false
    /*
    this.id = false
    this.title = ''
    this.author = ''
    this.artist = ''
    this.greeting = {}
    this.cover = {}
    this.owner = ''
    this.editors = []
    this.readers = []
    this.tags = []
    this.pages = []
*/

    this._insertTo = 0
    this.onePerPage = true
    this.data = {
      editors: [],
      readers: [],
      tags: [],
      pages: [{
        sounds: [],
        images: [],
        index: 0,
        page: 0,
      }],
      created: new Date(),
    }

    if (args) {
      this.data = Object.assign({}, args)
      // this.data = args
      // Object.keys(args).forEach((v) => {
      //   this[v] = args[v]
      // })
      this.fixup()
    }
  }

  /*
    checkModified () {
      let foundChange = ''
      // if (this.dirty) foundChange = 'dirty'
      // if (!this.dirty) {

        for (let ctr = 0; (ctr <= this.fieldsToCopyToJSON.length) && (foundChange === ''); ctr++) {
          const field = this.fieldsToCopyToJSON[ctr]
          if (!!this[field] !== !!this.original[field]) {
            console.log(field + ' MISMATCH: ')
            console.log(this[field])
            console.log(this.original[field])
            foundChange = field
            // wtfS
          } else {
            if (Array.isArray(this[field])) {
              if (this[field].length !== this.original[field].length) {
                foundChange = field
              } else {
                if (JSON.stringify(this[field]) !== JSON.stringify(this.original[field])) {
                  foundChange = field
                }
              }
            } else {
              // quick checks passed on simple string
              if (this[field] && this.original[field] && this[field] !== this.original[field]) {
                foundChange = field
              }
            }
          }
        }

      return foundChange
    }
  */

  addLocalFile (file) {
    // upload to fb
    // use as placeholder
    // capture added name, url
    // add to page
  }

  importBase (json) {
    this.base = Object.assign(this.base, json)
    const merged = []
    const common = Math.min(this.base.pages.length, this.data.pages.length)
    const max = Math.max(this.base.pages.length, this.data.pages.length)
    for (let ctr = 0; ctr < common; ctr++) {
      merged[ctr] = {
        images: this.base.pages[ctr].images.concat(this.data.pages[ctr].images),
        sounds: this.base.pages[ctr].sounds.concat(this.data.pages[ctr].sounds),
        index: ctr,
        page: ctr,
      }
    }

    // add the remaining from either
    if (max > common) {
      if (this.base.pages.length > this.data.pages.length) {
        for (let ctr = common; ctr < max; ctr++) {
          merged[ctr] = {
            images: this.base.pages[ctr].images.concat([]),
            sounds: this.base.pages[ctr].sounds.concat([]),
            index: ctr,
            page: ctr,
          }
        }
      }
      else {
        for (let ctr = common; ctr < max; ctr++) {
          merged[ctr] = {
            images: this.data.pages[ctr].images.concat([]),
            sounds: this.data.pages[ctr].sounds.concat([]),
            index: ctr,
            page: ctr,
          }
        }
      }
    }
    console.dir(merged)
    this.mergedPages = merged
  }

  addToBook (asset, fromLocal) {
    // nav store 9

    this.dirty = true
    let newRecord = false
    if (this.insertTo >= this.count) {
      this.count = this.insertTo + 1
    }

    switch (asset.ext) {
      default:
      case 'jpg':
      case 'png':
        // does the current page already have an image?
        if (this.onePerPage && this.pages[this.insertTo].images.length > 0) this.advanceInsertPage()
        this.pages[this.insertTo].images.push(asset)
        newRecord = {
          page: this.insertTo,
          collection: 'images',
          localURL: asset.latestImage || asset.localURL,
          nth: this.pages[this.insertTo].images.length - 1,
        } // this.pages[this.insertTo].images[this.pages[this.insertTo].images.length - 1]
        if (this.insertTo === 0) this.cover = asset
        break

      case 'wav':
      case 'mp3':
      case 'm4a':
        if (this.onePerPage && this.pages[this.insertTo].sounds.length > 0) this.advanceInsertPage()
        this.pages[this.insertTo].sounds.push(asset)
        newRecord = {
          page: this.insertTo,
          collection: 'sounds',
          nth: this.pages[this.insertTo].sounds.length - 1,
        } // this.pages[this.insertTo].sounds[this.pages[this.insertTo].sounds.length - 1]
        if (this.insertTo === 0) this.greeting = asset
        break
    }

    // this.asJSON()
    return newRecord
  }

  get count () {
    return this.pages.length
  }

  set count (val) {
    if (this.count < val) {
      for (let ctr = this.pages.length; ctr < val; ctr++) {
        this.pages.push({
          images: [],
          sounds: [],
          index: ctr,
          page: ctr,
        })
      }
    }
    else {
      // rules of truncating?
    }
  }

  get insertTo () {
    return this._insertTo
  }

  set insertTo (val) {
    this._insertTo = parseInt(val)
    if (this._insertTo < 0) this._insertTo = 0
    this.dirty = true
    if (this._insertTo <= this.count + 1) this.count = this._insertTo + 1
  }

  get nextPageForImage () {

    const pg = this.insertTo
    if (pg >= this.pages.length) return pg
    if (this.onePerPage && this.pages[pg].images.length > 0) return pg + 1
    return pg
  }

  get nextPageForSound () {

    const pg = this.insertTo
    if (pg >= this.pages.length) return pg
    if (this.onePerPage && this.pages[pg].sounds.length > 0) return pg + 1
    return pg
  }

  advanceInsertPage () {
    this.insertTo = this._insertTo + 1
    if (this.insertTo >= this.count) {
      this.count = this.insertTo + 1
    }
  }

  deDup (arr) {
    return Array.from(new Set(arr))
  }

  fixup () {

    if (!('created' in this) || (!this.created) || !('seconds' in this.created)) {
      this.created = new Date()
      console.log('created time "created"')
    }

    this.readers = this.deDup(this.readers)
    this.editors = this.deDup(this.editors)
    console.log(this.editors.length)
    if (this.data.pages.length < 1) {
      this.data.pages = [{
        sounds: [],
        images: [],
        index: 0,
        page: 0,
      }]
    }
    this.pages.forEach((pg, ctr) => {
      pg.index = ctr
      pg.page = ctr
    })
    this._insertTo = this.pages.length
    if (this.data.map || this.data.mapString ) this.map = JSON.parse(this.data.map || this.data.mapString)
    this.dirty = false
  }

  clearPage (pg) {
    pg.images = []
    pg.sounds = []
  }

  asJSON () {
    const here = this
    const j = {}

    this.fieldsToCopyToJSON.forEach(function (key) {
      j[key] = here[key] || ''
    })

    this.guardedFields.forEach(function (key) {
      j[key] = {}
      here.subfields.forEach(function (field) {
        j[key][field] = here[key][field] || ''
      })
    })

    j.pages = this.pages.map(pg => {
      const summary = {}
      summary.images = pg.images.map(img => {
        return {
          URL: img.URL,
          full: img.full,
          ext: img.ext,
          title: img.title || '',
          role: img.role || ''
        }
      })
      summary.sounds = pg.sounds.map(img => {
        return {
          URL: img.URL,
          full: img.full,
          ext: img.ext,
          title: img.title || '',
          role: img.role || ''
        }
      })
      return summary
    })

    return j
  }

  preferredTitle (page) {
    if (!page) return ''
    if (page.sounds.length > 0 && page.sounds[0].title) return page.sounds[0].title
    return 'Page ' + page.page
  }

  preferredImage (page) {
    if (!page) return ''
    if (page.images.length > 0) return page.images[0].URL
    if (this.cover) return this.cover.URL
    return ''
    // return 'src/statics/card shark torso.320.jpg'
  }

  get id () {
    return this.data.id || ''
  }

  set id (val) {
    this.data.id = val
    this.dirty = true
  }

  //<editor-fold desc="accessors for marking fields dirty">
  get title () {
    return this.data.title || ''
  }

  set title (val) {
    this.data.title = val
    this.dirty = true
  }

  get author () {
    return this.data.author || ''
  }

  set author (val) {
    this.data.author = val
    this.dirty = true
  }

  get artist () {
    return this.data.artist || ''
  }

  set artist (val) {
    this.data.artist = val
    this.dirty = true
  }

  get owner () {
    return this.data.owner || ''
  }

  set owner (val) {
    this.data.owner = val
    this.dirty = true
  }

  get ownerImage () {
    return this.data.ownerImage || ''
  }

  set ownerImage (val) {
    this.data.ownerImage = val
    this.dirty = true
  }

  get editors () {
    return this.data.editors || []
  }

  set editors (val) {
    this.data.editors = val
    this.dirty = true
  }

  get readers () {
    return this.data.readers || []
  }

  set readers (val) {
    this.data.readers = val
    this.dirty = true
  }

  get tags () {
    return this.data.tags || []
  }

  set tags (val) {

    this.data.tags = val
    this.dirty = true
  }

  get pages () {
    if (this.mergedPages) return this.mergedPages
    return this.data.pages || []
  }

  set pages (val) {
    this.data.pages = val
    this.dirty = true
  }

  get created () {
    return this.data.created || ''
  }

  set created (val) {
    this.data.created = val
    this.dirty = true
  }

  get updated () {
    return this.data.updated || ''
  }

  set updated (val) {
    this.data.updated = val
    this.dirty = true
  }

  get greeting () {
    return this.data.greeting || {}
  }

  set greeting (val) {
    this.data.greeting = val
    this.dirty = true
  }

  get cover () {
    return this.data.cover || {}
  }

  set cover (val) {
    this.data.cover = val
    this.dirty = true
  }
  get public () {
    return this.data.public || {}
  }

  set public (val) {
    this.data.public = val
    this.dirty = true
  }
  get published () {
    return this.data.published || {}
  }

  set published (val) {
    this.data.published = val
    this.dirty = true
  }
  //</editor-fold>

  URL (override) { // https://localhost:8080/#/Count%20to%20Ten/play/aTXiX5dgiw31gW3wDM3t
    return `/${encodeURIComponent(this.title)}/${override || 'play'}/${encodeURIComponent(this.id)}`
  }
}
